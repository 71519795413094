import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { ParametreKeys } from '~~/types/Enums';

export const useParametresStore = defineStore({
  id: 'parametres-store',
  state: () => {
    return {
      page: 1,
      pageCount: 1,
      sortField: 'libelle',
      sortDirection: ':asc',
      query: '',
      api: useApi.parametres,
    };
  },
  actions: {
    async update(item) {
      item.valeur = item.valeur?.toString() || null;
      return await this.api.update(item);
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query) {
      this.page = 1;
      this.query = query;
    },

    paramByKey(key) {
      return this.paramByKeyForParametres(this.collection, key);
    },

    paramByKeyForParametres(parametres, key) {
      const param = parametres.find((item) => item.clef === key);
      if (param) {
        return param;
      }
      return { valeur: '0' };
    },

    async createParametre({ clef, libelle, description, valeur, type, categorie, ordre }) {
      const res = await this.api.create({
        clef,
        libelle,
        description,
        valeur,
        type,
        categorie,
        ordre,
      });
      if (res?.data) {
        useAuthStore().workspace.parametres.push(res.data);
        return res.data;
      }

      return null;
    },
  },
  getters: {
    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),
    collection() {
      return useAuthStore().workspace?.parametres || [];
    },
    parametresByCategories(state) {
      const result = [];
      state.collection.forEach((item) => {
        const categorie = result.find((categorie) => categorie.id === item.categorie);
        if (categorie) {
          categorie.items.push(item);
          categorie.items.sort((a, b) => a.ordre - b.ordre);
        } else {
          result.push({
            id: item.categorie,
            libelle: item.categorie,
            items: [item],
          });
        }
      });
      return result;
    },

    parametresForCategorie: (state) => (categorie) => {
      return state.collection
        .filter((item) => item.categorie === categorie)
        .sort((a, b) => a.ordre - b.ordre);
    },

    tauxTva: (state) => {
      return parseFloat(state.paramByKey(ParametreKeys.TAUX_TVA)?.valeur || 0) * 0.01 + 1;
    },

    tvaPossibleValues: (state) => {
      let values = [
        parseFloat(state.paramByKey(ParametreKeys.TAUX_TVA)?.valeur),
        parseFloat(state.paramByKey(ParametreKeys.TAUX_TVA_1)?.valeur),
        parseFloat(state.paramByKey(ParametreKeys.TAUX_TVA_2)?.valeur),
      ];

      values = values.filter((v) => v !== undefined && v !== null && !isNaN(v) && v !== 0);

      return values.map((v) => ({ id: v, name: `${v}%` }));
    },
  },
});
